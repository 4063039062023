import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { HomePagequery_homepage_categories } from "../../graphql/types";
import { mq } from "../../libs/emotion-theme";
import Box from "../Box";
import { whiteButtonStyle } from "../Button";
import CustomLink from "../CustomLink";
import Flex from "../Flex";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/dist/client/router";
import { localePath, Locale } from "../../routes";
import { makeSlugUrl } from "../../helpers/url";

interface Props {
  categories?: (null | HomePagequery_homepage_categories)[] | null;
}

const Categories = ({ categories }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation("homepage");

  return (
    <CategoriesContaier flexDirection={{ _: "column", md: "row" }}>
      {categories?.map((category) => (
        <CustomLink
          css={categoryLinkWrapperStyle}
          ariaLabel={category?.title}
          key={category?.slug}
          href={`${localePath[locale as Locale].categories}/${makeSlugUrl(
            category
          )}`}
        >
          <BackgroundImage bgImage={category?.image?.url} />
          <CategoryItem pl={{_: 5, lg: 6}}> 
            <Title>{category?.title}</Title>
            <Description> {t("categoryDesc")}</Description>
            <Box>
              <div css={whiteButtonStyle}>{t("categoryButton")}</div>
            </Box>
          </CategoryItem>
        </CustomLink>
      ))}
    </CategoriesContaier>
  );
};

export default Categories;

const BackgroundImage = styled.div<{ bgImage?: string }>`
  background-image: ${({ bgImage }) =>
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent, transparent, transparent), url(${bgImage})`};
  background-position: top center;
  transition: all 0.4s;
  background-size: cover;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const categoryLinkWrapperStyle = css`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 420px;
  width: 100%;

  :hover ${BackgroundImage} {
    transform: scale(1.1);
  }

  ${mq[1]} {
    width: 50%auto;
  }
`;

const CategoriesContaier = styled(Flex)`
  justify-content: center;
  position: relative;
`;

const CategoryItem = styled(Flex)`
  justify-content: flex-end;
  padding: 36px 64px;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 80px;
  line-height: 100px;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: 'Kodchasan', sans-serif;
  margin: 0;
  text-transform: lowercase;
`;

const Description = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 16px;
`;
