import { Theme } from "@emotion/react";
import { Interpolation } from "@emotion/styled";
import Markdown from "markdown-to-jsx";
import React from "react";
import Flex from "./Flex";
import Title from "./Title";

interface Props {
  title?: string | null;
  description?: string | null;
  descriptionStyle?: Interpolation<Theme>;
}

const TextBlock = ({ title, description, descriptionStyle }: Props) => (
  <Flex flexDirection="column" alignItems="center">
    <Title>{title}</Title>
    <span css={descriptionStyle}>
      <Markdown>{description || ""}</Markdown>
    </span>
  </Flex>
);

export default TextBlock;
