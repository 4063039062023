import { css, Theme } from "@emotion/react";
import React from "react";
import Slider from "react-slick";
import { HomePagequery_homepage_activities } from "../../graphql/types";
import CustomLink from "../CustomLink";
import { itemContainerStyle } from "../ItemContainer";
import { default as ArrowDownSVG } from "../../assets/arrow-down.svg";
import Box from "../Box";
import { Locale, localePath } from "../../routes";
import { useRouter } from "next/dist/client/router";
import { makeSlugUrl } from "../../helpers/url";

interface Props {
  activities?: (HomePagequery_homepage_activities | null)[] | null;
}

const Arrow = (props: any) => {
  const { direction, style, ...other } = props;
  return (
    <div {...other} style={{ ...style, width: 40, height: 40 }}>
      <ArrowDownSVG
        style={{
          transform: `rotate(${direction === "left" ? 90 : -90}deg)`,
          width: 30,
          height: 40,
        }}
      />
    </div>
  );
};

const ActivityCarousel = ({ activities }: Props) => {
  const mapActivitiesAmount = activities?.length || 0;
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: mapActivitiesAmount <= 7 ? mapActivitiesAmount : 7,
    slidesToScroll: 1,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: mapActivitiesAmount <= 2 ? mapActivitiesAmount : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: mapActivitiesAmount <= 2 ? mapActivitiesAmount : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: mapActivitiesAmount <= 3 ? mapActivitiesAmount : 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: mapActivitiesAmount <= 4 ? mapActivitiesAmount : 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: mapActivitiesAmount <= 5 ? mapActivitiesAmount : 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000000,
        settings: {
          slidesToShow: mapActivitiesAmount <= 6 ? mapActivitiesAmount : 6,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { locale } = useRouter();
  return (
    <Box px={5}>
      <Slider {...settings}>
        {activities?.map((activity) => (
          <CustomLink
            ariaLabel={activity?.title}
            css={menuLinkStyle}
            href={`${localePath[locale as Locale].activities}/${makeSlugUrl(
              activity
            )}`}
            key={activity?.slug}
          >
            {activity?.title}
          </CustomLink>
        ))}
      </Slider>
    </Box>
  );
};

export default ActivityCarousel;

const menuLinkStyle = (theme: Theme) => css`
  ${itemContainerStyle(theme)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 120px;
  height: 120px;
  margin: auto;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.small};
  font-family: 'Kodchasan', sans-serif;
`;
