import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import Flex from "./Flex";

export const itemContainerStyle = (theme: Theme, isBlack?: boolean, noBorder?: boolean) => css`
  border: ${noBorder ? 'none' : `1px solid ${theme.colors.text}`};
  padding: 16px;
  background-color: ${isBlack ? theme.colors.text : theme.colors.secondary};
  color: ${isBlack ? theme.colors.secondary : theme.colors.text};
  flex-direction: column;
`;

const ItemContainer = styled(Flex)<{ isBlack?: boolean, noBorder?: boolean }>`
  ${({ theme, isBlack, noBorder }) => itemContainerStyle(theme, isBlack, noBorder)}
`;

export default ItemContainer;
