import { useQuery } from "@apollo/client";
import { css } from "@emotion/react";
import { GetServerSideProps } from "next";
import React from "react";
import Box from "../components/Box";
import Activities from "../components/homepage/Activities";
import Categories from "../components/homepage/Categories";
import HowItWorks from "../components/homepage/HowItWorks";
import ShopLayout from "../components/layouts/ShopLayout";
import TextBlock from "../components/TextBlock";
import { footerQuery, headerQuery, homePageQuery } from "../graphql/queries";
import { HomePagequery } from "../graphql/types";
import { initializeApollo } from "../libs/apollo-client";
import { mq } from "../libs/emotion-theme";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export default function Home() {
  const { data } = useQuery<HomePagequery>(homePageQuery);
  return (
    <ShopLayout title={data?.homepage?.seo?.metaTitle} metaDescription={data?.homepage?.seo?.metaDescription}>
      <Box mt={-58}>
        <Categories categories={data?.homepage?.categories} />
        <Activities activities={data?.homepage?.activities} />
        <HowItWorks
          title={data?.homepage?.presentationTitle}
          items={data?.homepage?.presentationItems}
        />
        <Box my={5}>
          <TextBlock
            title={data?.homepage?.ourGoalTitle}
            description={data?.homepage?.ourGoalDescription}
            descriptionStyle={ourGoalDescription}
          />
        </Box>
      </Box>
    </ShopLayout>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const apolloClient = initializeApollo(null, locale);
  await apolloClient.query({
    query: footerQuery,
  });
  await apolloClient.query({
    query: homePageQuery,
  });
  await apolloClient.query({
    query: headerQuery
  });

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
      ...(await serverSideTranslations(locale as string, ["homepage", "common"])),
    },
  };
};

const ourGoalDescription = css`
  width: 80%;
  text-align: center;

  ${mq[1]} {
    width: 50%;
  }
`;
