import React from "react";
import { HomePagequery_homepage_activities } from "../../graphql/types";
import Box from "../Box";
import { blackButtonStyle } from "../Button";
import Flex from "../Flex";
import ActivityCarousel from "./ActivityCarousel";
import { useTranslation } from "next-i18next";
import CustomLink from "../CustomLink";
import { Locale, localePath } from "../../routes";
import { useRouter } from "next/dist/client/router";

interface Props {
  activities?: (HomePagequery_homepage_activities | null)[] | null;
}

const Activities = ({ activities }: Props) => {
  const { t } = useTranslation("homepage");
  const { locale } = useRouter();

  const orderedActivities = [...(activities || [])]?.sort(
    (a1, a2) => (a1?.order || 100) - (a2?.order || 100)
  );
  return (
    <Flex
      mt={5}
      width={1}
      alignItems="center"
      flexDirection={{ _: "column", md: "row" }}
    >
      <Box width={{ _: 1, md: 0.8 }}>
        <ActivityCarousel activities={orderedActivities} />
      </Box>
      <Box ml={{ _: 0, md: 3 }} mt={{ _: 4, md: 0 }} mr={3}>
        <CustomLink
          css={blackButtonStyle}
          href={localePath[locale as Locale].activities}
        >
          {t("activitiesButton")}
        </CustomLink>
      </Box>
    </Flex>
  );
};

export default Activities;
