import styled from "@emotion/styled";
import React from "react";
import { HomePagequery_homepage_presentationItems } from "../../graphql/types";
import Flex from "../Flex";
import ItemContainer from "../ItemContainer";
import Title from "../Title";

interface Props {
  title?: string | null;
  items?: (HomePagequery_homepage_presentationItems | null)[] | null;
}

const HowItWorks = ({ title, items }: Props) => {
  return (
    <Flex flexDirection="column" alignItems="center" mt={5}>
      <Title level={2} css={{ textAlign: "center" }}>
        {title}
      </Title>
      <Flex
        flexDirection={{ _: "column", md: "row" }}
        mt={3}
        alignItems={{ _: "center", md: "initial" }}
      >
        {items?.map((item) => (
          <ItemContainer
            width={{ _: 2 / 3, md: 1 / 4 }}
            maxWidth={{ _: "auto", md: 220 }}
            key={item?.order}
            mx={{ _: 0, md: 3 }}
            my={{ _: 3, md: 0 }}
            alignItems="center"
          >
            <ItemOrder>{item?.order}</ItemOrder>
            <Separator />
            <ItemTitle>{item?.title}</ItemTitle>
          </ItemContainer>
        ))}
      </Flex>
    </Flex>
  );
};

export default HowItWorks;

const ItemOrder = styled.span`
  font-weight: 800;
  font-size: 18px;
`;

const ItemTitle = styled.span`
  text-align: center;
`;

const Separator = styled.div`
  border-bottom: 1px solid black;
  width: 50%;
  margin: 8px 0;
`;
